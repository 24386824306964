export default {
    prehandlers: `enum ApiType {
    CONSUMER,
    CLIENT,
    EVENT_DISPATCHER,
}
enum Source {
    JWT,
    PREHANDLER,
    REQUEST_BODY,
    REQUEST_QUERY_PARAMETER,
    BACKEND,
    EXPIRED_JWT,
}

enum PersonDataScope {
    PUBLIC,
    PRIVATE,
    CLIENT,
}
interface EventData {
    getName(): string;
    getValue(): Record<string, string>;
    getSource(): Source;
    isVerified(): boolean;
}
interface Sandbox {
    getSandboxId(): string;
    getSandbox(): string;
    getContainer(): string;
}
interface Country {
    getIsoCode(): string;
    getName(): string;
}
interface City {
    getName(): string;
}
interface State {
    getIsoCode(): string;
    getName(): string;
}
interface GeoLocation {
    getLatitude(): number;
    getLongitude(): number;
}
interface GeoIp {
    getIpAddress(): string;
    getCountry(): Country | null;
    getState(): State | null;
    getCity(): City | null;
    getLocation(): GeoLocation | null;
}
interface ClientDomainContext {
    getClientDomain(): string;
    getClientDomainId(): string;
}
interface PersonReward {
    getRewardSlots(): Array<string>;
    getPersonRole(): string;
    getFaceValue(): string;
    getFaceValueType(): string;
    getId(): string;
    getRewardId(): string | null;
    getDateEarned(): string;
    getState(): string;
    getPartnerRewardId(): string | null;
    getSandbox(): string | null;
    getProgramLabel(): string | null;
    getCampaignId(): string | null;
    getRewardedDate(): string;
    getRewardSupplierId(): string;
    getData(): Map<string, string>;
    expiryDate?(): string;
}
interface PersonReferral {
    getClientId(): string;
    getOtherPersonId(): string;
    getMySide(): string;
    getCreatedDate(): string;
    getUpdatedDate(): string;
    getReason(): string;
    isDisplaced(): boolean;
    getContainer(): string;
}
interface RequestContext {
    getIp(): string;
    getDeviceId(): string;
    getCreatedAt(): string;
    getGeoIp(): GeoIp;
}
interface PartnerEventId {
    getName(): string;
    getValue(): string;
}
interface PersonStep {
    getPersonId(): string;
    getCampaignId(): string;
    getProgramLabel(): string;
    getStepName(): string;
    getEventId(): string;
    getRootEventId(): string;
    getEventDate(): string;
    getCreatedDate(): string;
    getValue(): string | null;
    isAliasName(): boolean;
    getQuality(): string;
    getPartnerEventId(): PartnerEventId | null;
    getData(): Map<string, Record<string, unknown>>;
    getPublicData(): Map<string, Record<string, unknown>>;
    getPrivateData(): Map<string, Record<string, unknown>>;
}
interface ShareableContent {
    getPartnerContentId(): string | null;
    getTitle(): string | null;
    getImageUrl(): string | null;
    getDescription(): string | null;
    getUrl(): string | null;
}
interface Shareable {
    getId(): string;
    getKey(): string;
    getCode(): string;
    getContent(): ShareableContent;
    getData(): Map<string, string>;
    getLabel(): string | null;
    getClientDomainId(): string;
}
interface Person {
    getId(): string;
    getEmail(): string | null;
    getNormalizedEmail(): string | null;
    getDisplacedPersonId(): string | null;
    getFirstName(): string | null;
    getLastName(): string | null;
    getLocale(): string | null;
    isBlocked(): boolean;
    getPrivateData(): Map<string, Record<string, unknown>>;
    getPublicData(): Map<string, Record<string, unknown>>;
    getData(): Map<string, Record<string, unknown>>;
    getPreferredLocale(): string;
    getRewards(): Array<PersonReward>;
    getRecentAssociatedFriends(): Array<PersonReferral>;
    getRecentAssociatedAdvocates(): Array<PersonReferral>;
    getRecentRequestContexts(): Array<RequestContext>;
    getSteps(): Array<PersonStep>;
    getShareables(): Array<Shareable>;
    getPartnerUserId(): string | null;
}
interface ProcessedRawEventBuilder {
    withClientDomain(clientDomain: string): ProcessedRawEventBuilder;
    withEventName(eventName: string): ProcessedRawEventBuilder;
    withEventTime(eventTime: string): ProcessedRawEventBuilder;
    withSandbox(sandboxId: string): ProcessedRawEventBuilder;
    withAppType(appType: string): ProcessedRawEventBuilder;
    withDefaultAppType(defaultAppType: string): ProcessedRawEventBuilder;
    addSourceGeoIp(sourceGeoIp: string): ProcessedRawEventBuilder;
    removeSourceGeoIp(sourceGeoIp: string): ProcessedRawEventBuilder;
    withDeviceId(deviceId: string): ProcessedRawEventBuilder;
    withPageId(pageId: string): ProcessedRawEventBuilder;
    addJwt(jwt: string): ProcessedRawEventBuilder;
    addData(
        data: Record<string, Record<string, unknown>> | string,
        value?: Record<string, string> | string
    ): ProcessedRawEventBuilder;
    removeData(name: string): ProcessedRawEventBuilder;
}
interface RawEvent {
    getApiType(): ApiType;
    getClientDomainContext(): ClientDomainContext;
    getRawEventId(): string;
    getRequestTime(): string;
    getEventName(): string;
    getUrl(): string;
    getReferrer(): string;
    getHttpRequestBody(): string | null;
    getHttpRequestMethod(): string;
    getSourceIps(): string[];
    getHttpHeaders(): Map<string, string[]>;
    getHttpCookies(): Map<string, string[]>;
    getHttpParameters(): Map<string, string[]>;
}
interface ProcessedRawEvent {
    getClientDomain(): ClientDomainContext;
    getEventName(): string;
    getEventTime(): string;
    getSandbox(): Sandbox;
    getSourceGeoIps(): GeoIp[];
    getDeviceId(): string;
    getPageId(): string;
    getAppType(): string;
    getAppData(): Record<string, string>;
    getData(): Map<string, Record<string, string> | string | null>;
    getVerifiedData(): Record<string, Record<string, unknown>>;
    getAllData(): string[];
}

interface EncoderService {
    safeHtml(input: string): string;
    safeHtmlContent(input: string): string;
    safeHtmlAttribute(input: string): string;
    safeHtmlUnquotedAttribute(input: string): string;
    safeCssString(input: string): string;
    safeCssUrl(input: string): string;
    safeJs(input: string): string;
    safeJsBlock(input: string): string;
    safeJsAttribute(input: string): string;
    safeUriComponent(input: string): string;
    encodeWithHS256Algorithm(key: string, message: string): string;
    encodeWithSha256(input: string): string;
    encodeWithBase64(input: string): string;
}

interface PublicClientDomain {
    getClientDomain(): string;
    getClientDomainId(): string;
}

interface PublicClientDomainService {
    getPublicClientDomains(): PublicClientDomain[];
}
interface ClientContext {
    getClientId(): string;
    getClientShortName(): string;
    getTimezone(): string;
}

interface GlobalContext {
    getClientContext(): ClientContext;

    getGlobalServices(): GlobalServices;
}

interface VerifiedEmail {
    getTitle(): string | null;
    getAddress(): string;
    getNormalizedAddress(): string;
}
interface EmailVerification {
    isEmailValid(): boolean;
    getVerifiedEmail(): VerifiedEmail | null;
}

interface EmailVerificationService {
    isEmailValid(email: string): boolean;
    verifyEmail(email: string): EmailVerification;
}

interface ShareableContentBuilder {
    withPartnerContentId(partnerContentId: string): ShareableContentBuilder;
    withTitle(title: string): ShareableContentBuilder;
    withImageUrl(imageUrl: string): ShareableContentBuilder;
    withDescription(description: string): ShareableContentBuilder;
    withUrl(url: string): ShareableContentBuilder;
}

interface ShareableBuilder {
    getContentBuilder(): ShareableContentBuilder;
    withKey(key: string): ShareableBuilder;
    withCode(code: string): ShareableBuilder;
    withPreferredCodePrefixes(
        preferredCodePrefixes: Array<string>
    ): ShareableBuilder;
    withLabel(label: string): ShareableBuilder;
    withNoLabel(): ShareableBuilder;
    addData(name: string, value: string): ShareableBuilder;
    done(): PersonBuilder;
}

interface PersonBuilder {
    withFirstName(firstName: string): PersonBuilder;
    withLastName(lastName: string): PersonBuilder;
    withEmail(email: string): PersonBuilder;
    withPartnerUserId(partnerUserId: string): PersonBuilder;
    withDisplacedPerson(displacedPersonId: string): PersonBuilder;
    addKey(type: string, value: string): PersonBuilder;
    addData(name: string, value: string, scope: string): PersonBuilder;
    createShareable(): ShareableBuilder;
    save(): Person;
}

interface PersonLookupBuilder {
    withPersonId(personId: string): PersonLookupBuilder;
    withEmail(email: string): PersonLookupBuilder;
    withPersonKey(type: string, value: string): PersonLookupBuilder;
    lookup(): Person | null;
}
interface PersonService {
    createPerson(): PersonBuilder;
    lookupPerson(): PersonLookupBuilder;
}
interface JsonService {
    readJsonPath(
        json: Record<string, string>,
        jsonPath: string
    ): Record<string, string> | null;
    toJsonString(object: Record<string, string>): string;
}

interface NotificationBuilder {
    withName(name: string): NotificationBuilder;
    withMessage(message: string): NotificationBuilder;
    withLevel(level: string): NotificationBuilder;
    withScope(scope: string): NotificationBuilder;
    addTag(tag: string): NotificationBuilder;
    addParameter(name: string, value: string): NotificationBuilder;
    send(): void;
}

interface NotificationService {
    createNotification(): NotificationBuilder;
}

interface StringService {
    split(value: string | null): string[];
}

interface CouponService {
    extoleIssued(coupons: string): string;
}

interface JwtBuilder {
    withHeaderParameter(name: string, value: string): JwtBuilder;
    withCustomClaim(name: string, value: Record<string, any>): JwtBuilder;
    withIssuer(issuer: string): JwtBuilder;
    withAudiences(audienceList: Array<string>): JwtBuilder;
    withAudience(audience: string): JwtBuilder;
    withExpirationDate(secondsSince1970: number): JwtBuilder;
    withSubject(subject: string): JwtBuilder;
    withNotBefore(secondsSince1970: number): JwtBuilder;
    withIssuedAt(secondsSince1970: number): JwtBuilder;
    withJwtId(jwtId: string): JwtBuilder;
    signWithKeyByClientKeyId(extoleClientKeyId: string): JwtBuilder;
    signWithKeyByPartnerKeyId(partnerKeyId: string): JwtBuilder;
    build(): string;
}

interface JwtService {
    createJwtBuilder(): JwtBuilder;
}

interface DateService {
    now(): string;
    toClientTimezone(dateTime: string, sameLocal?: boolean): string;
    toTimezone(dateTime: string, timezone: string, sameLocal?: boolean): string;
}

interface GlobalServices {
    getPublicClientDomainService(): PublicClientDomainService;
    getEncoderService(): EncoderService;
    getEmailVerificationService(): EmailVerificationService;
    getPersonService(): PersonService;
    getJsonService(): JsonService;
    getNotificationService(): NotificationService;
    getStringService(): StringService;
    getCouponService(): CouponService;
    getJwtService(): JwtService;
    getDateService(): DateService;
}

interface PrehandlerContext extends GlobalContext {
    getRawEvent(): RawEvent;
    getEventBuilder(): ProcessedRawEventBuilder;
    getProcessedRawEvent(): ProcessedRawEvent;
    getCandidatePerson(): Person;
    replaceCandidatePerson(person: Person): void;
    addLogMessage(message: string): void;
}

const context: PrehandlerContext;
`,
};
