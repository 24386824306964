import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import tokenizerService from "src/labs/campaign-controllers/tokenizer-service";
import singleSelectService from "src/labs/campaign-controllers/single-select-service";
import calendarService from "src/labs/campaign-controllers/calendar-service";
import featureFlagService from "src/shared/feature-flag-service";
import EditEvaluatableModalView from "src/shared/ui-components/evaluatable-field/EditEvaluatableModalView";
import Strings from "src/shared/Strings";
import dropdownTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/dropdown-template.html?raw";
import iconDropdownTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/icon-dropdown-template.html?raw";
import tokenizerTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/tokenizer-template.html?raw";
import singleSelectTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/single-select-template.html?raw";
import calendarTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/calendar-template.html?raw";
import extendedTokenizerTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/extended-tokenizer-template.html?raw";
import checkboxTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/checkbox-template.html?raw";
import template from "src/shared/ui-components/evaluatable-field/editable-evaluatable-field-template.html?raw";

const UI_TEMPLATES = {
    dropdown: _.template(dropdownTemplate),
    icon_dropdown: _.template(iconDropdownTemplate),
    single_select: _.template(singleSelectTemplate),
    calendar: _.template(calendarTemplate),
    input: _.template(template),
    tokenizer: _.template(tokenizerTemplate),
    extended_tokenizer: _.template(extendedTokenizerTemplate),
    checkbox: _.template(checkboxTemplate),
};

const EditableEvaluatableFieldView = Backbone.View.extend({
    template: _.template(template),

    dropdownTemplate: _.template(dropdownTemplate),

    events: {
        "click .js-open-evaluatable-modal": "openEvaluatableModal",
    },

    initialize(options) {
        this.service = options.service;
        this.fieldName = this.$el.data("field-name");
        this.options =
            (this.$el.data("options") && this.$el.data("options").split(",")) ||
            [];
        this.defaultOptions =
            (this.$el.data("default-options") &&
                this.$el.data("default-options").split(",")) ||
            [];
        this.allValues = this.$el.data("all-values");
        this.builtValue = this.$el.data("built-value");
        this.uiType = this.$el.data("ui-type");
        this.type = this.$el.data("type") || "string";
        this.isOmissible = this.$el.data("isOmissible");
        this.isInteger = this.type === "integer";
        this.placeholder = this.$el.data("placeholder");
        this.staticTemplate = this.$el.data("template");
        this.keepCase = this.$el.data("keep-case");
        this.initialValue = this.model.get(this.fieldName);
        this.templatizedCollection = this.model.templatizedCollection;
        if (this.templatizedCollection) {
            this.templatedModel = this.templatizedCollection.findWhere({
                flow_step_id: this.model.get("flow_step_id"),
            });
        }
        this.templatedValue =
            this.templatedModel && this.templatedModel.get(this.fieldName);
        this.uniqueId = _.uniqueId();
    },

    render(value) {
        const data = value ?? this.initialValue;
        const templatedValue =
            value || this.templatedValue || this.initialValue;
        const isEvaluatable =
            this.service.isEvaluatable(data) ||
            this.service.isEvaluatable(this.templatedValue);
        if (isEvaluatable && this.builtValue === undefined) {
            this.template = UI_TEMPLATES.input;
        } else {
            this.template =
                UI_TEMPLATES[this.uiType] ||
                _.template($(this.staticTemplate).html() || "<div></div>");
        }
        this.$el.html(
            this.template({
                fieldName: this.fieldName,
                data: _.isArray(data) ? data.join(", ") : data,
                builtValue: this.builtValue,
                templatedValue,
                isEvaluatable,
                isOmissible: this.isOmissible,
                isInteger: this.isInteger,
                options: this.options,
                placeholder: this.placeholder,
                defaultOptions: this.defaultOptions,
                allValues: _.isArray(this.allValues)
                    ? JSON.stringify(this.allValues)
                    : this.allValues,
                withEditModal: true,
                keepCase: this.keepCase,
                isEvaluatableEditingEnabled: featureFlagService.isOn(
                    featureFlagService.FLAGS.ALLOW_EVALUATABLE_EDITING
                ),
                uniqueId: this.uniqueId,
                Strings,
            })
        );
        this.$tokenizer = this.$(".js-tokenizer-wrapper");
        this.$multiSelectWrapper = this.$(".js-multi-select-wrapper");
        this.$singleSelectWrapper = this.$(".js-single-select-wrapper");
        tokenizerService.renderTokenizers(this.$tokenizer);
        if (this.dataType === "boolean" && this.uiType === "single_select") {
            singleSelectService.renderBooleanFields(this.$singleSelectWrapper);
        } else {
            singleSelectService.renderSingleSelectFields(
                this.$singleSelectWrapper,
                this.options,
                this.dataType
            );
        }
        calendarService.renderFields(this.$(".js-calendar-wrapper"));
        return this;
    },

    openEvaluatableModal() {
        const $sourceField = this.$(":input");
        const modalView = EditEvaluatableModalView.create(
            $sourceField,
            this.uiType === "checkbox" ? this.initialValue : this.templatedValue
        );
        this.listenTo(
            modalView,
            EditEvaluatableModalView.EVENTS.CONFIRMED,
            (value) => {
                const formattedValue =
                    this.type === "boolean" && !value ? false : value;
                this.render(formattedValue);
                this.trigger("fieldUpdated", this.fieldName, formattedValue);
            }
        );
    },
});

EditableEvaluatableFieldView.create = function (el, model, service) {
    return new EditableEvaluatableFieldView({
        el,
        model,
        service,
    }).render();
};

export default EditableEvaluatableFieldView;
