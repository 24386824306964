import { createApp } from "vue";
import _ from "underscore";
import Backbone from "backbone";
import Wrapper from "./Wrapper.vue";

const CodeEditorView = Backbone.View.extend({
    initialize(options) {
        this.eventBus = _.extend({}, Backbone.Events);
        const { className, contextType, value } = options;
        const onGetValue = (inputValue) => {
            this.value = inputValue;
        };
        this.codeEditor = createApp(Wrapper, {
            eventBus: this.eventBus,
            value,
            className,
            contextType,
            onGetValue,
        }).mount(this.el);
    },

    setValue() {
        this.eventBus.trigger("emitEvent");
        return this.value;
    },
});

CodeEditorView.create = (el, value, className, contextType) => {
    return new CodeEditorView({
        el,
        value,
        className,
        contextType,
    });
};

export default CodeEditorView;
