import $ from "jquery";
import _ from "underscore";
import Keys from "src/shared/Keys";
import tokenizerFieldWithSwitchTemplate from "src/labs/campaign-controllers/tokenizer-field-with-switch-template.html?raw";
import tokenizerFieldTemplate from "src/labs/campaign-controllers/tokenizer-field-template.html?raw";
import "tokenize";

const MAX_ITEMS_IN_DROPDOWN = 10000;

const tokenizerTemplate = _.template(tokenizerFieldTemplate);
const tokenizerWithSwitchTemplate = _.template(
    tokenizerFieldWithSwitchTemplate
);

function simulateEnterKeyPress(event) {
    const keyDownEvent = $.Event("keydown");
    keyDownEvent.which = Keys.ENTER;
    keyDownEvent.keyCode = Keys.ENTER;
    $(event.currentTarget).trigger(keyDownEvent);
}

function updateField($wrapper) {
    const values = $wrapper
        .find(".Token")
        .map(function () {
            return $(this).data("value");
        })
        .get();
    $wrapper.find(".js-value-field").val(values.join(",")).trigger("change");
}

function toArray(string) {
    if (string) {
        return _.isString(string)
            ? decodeURIComponent(string)
                  .split(",")
                  .map((value) => value.trim())
            : string;
    }
    return [];
}

function formatAllValues(allValues, selectedValues) {
    if (_.isString(allValues)) {
        allValues = JSON.parse(allValues);
    }
    return _.map(allValues, (valueItem) =>
        _.extend(
            {
                isSelected: _.contains(selectedValues, valueItem.value),
            },
            valueItem
        )
    );
}

function formatValues(values) {
    return _.map(values, (value) => ({
        name: value,
        value,
        isSelected: true,
    }));
}

function renderTokenizer($item) {
    const fieldName = $item.data("field-name");
    const value = decodeURIComponent($item.data("value").toString() || "");
    const isOmissible = $item.data("isOmissible");
    const selectedValues = toArray(value);
    const allValues = $item.data("all-values");
    const values = _.isEmpty(allValues)
        ? formatValues(selectedValues)
        : formatAllValues(allValues, selectedValues);
    $item.html(
        tokenizerTemplate({
            fieldName,
            value,
            values,
            isOmissible,
        })
    );
    const tokenizer = $item.find(".js-tokenizer").tokenize({
        onAddToken: updateField.bind(null, $item),
        onRemoveToken: updateField.bind(null, $item),
        minChars: 0,
        nbDropdownElements: MAX_ITEMS_IN_DROPDOWN,
    });
    $item.find(".TokensContainer").addClass("TokensContainer--expandable");
    $item.find(".TokenSearch > input").on("blur", (event) => {
        if ($item.find(".TokenSearch > input").val()) {
            simulateEnterKeyPress(event);
        }
    });
    setTimeout(() => $item.find(".js-value-field").trigger("change"), 0);
    return tokenizer;
}

export default {
    renderTokenizers($selectors) {
        $selectors.each(function () {
            renderTokenizer($(this));
        });
    },

    renderTokenizersWithSwitch($selectors) {
        $selectors.each(function () {
            const $item = $(this);
            const fieldName = $item.data("field-name");
            const label = $item.data("label");
            const values = toArray($item.data("value"));
            const isOmissible = $item.data("isOmissible");
            $item.html(
                tokenizerWithSwitchTemplate({
                    id: _.uniqueId(),
                    label,
                    values,
                    fieldName,
                    isOmissible,
                })
            );
            const tokenizer = renderTokenizer(
                $item.find(".js-tokenizer-wrapper")
            );
            const $switch = $item.find(".js-switch-field");
            const $valueField = $item.find(".js-value-field");
            $switch.on("change", function () {
                const isTokenizerEnabled = $(this).is(":checked");
                if (isTokenizerEnabled) {
                    tokenizer.enable();
                } else {
                    tokenizer.disable();
                    $item.find(".Token").remove();
                    $valueField.val("");
                }
            });
            $switch.change();
        });
    },
};
