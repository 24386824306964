import $ from "jquery";
import _ from "underscore";
import Strings from "src/shared/Strings";
import fieldTemplate from "src/labs/campaign-controllers/multi-select-field-template.html?raw";

const template = _.template(fieldTemplate);

function toggleMultiSelectOption($wrapper, event) {
    const $target = $(event.currentTarget);
    const $list = $wrapper.find(".js-multi-select");
    const fieldName = $list.data("field-name");
    $target.toggleClass("active");
    const values = $list
        .find(".active")
        .map(function () {
            return $(this).data("option");
        })
        .get();
    $wrapper.find(`[name='${fieldName}']`).val(values.join(","));
}

export default {
    renderMultiSelectFields($selector) {
        $selector.each(function () {
            const $item = $(this);
            const fieldName = $item.data("field-name");
            const options = $item.data("options");
            const values = $item.data("field-values");
            $item.html(
                template({
                    Strings,
                    fieldName,
                    options: options.split(","),
                    values: decodeURIComponent(values).split(","),
                })
            );
            $item
                .find("li")
                .on("click", toggleMultiSelectOption.bind(null, $item));
        });
    },
};
