import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import tokenizerService from "src/labs/campaign-controllers/tokenizer-service";
import multiSelectService from "src/labs/campaign-controllers/multi-select-service";
import singleSelectService from "src/labs/campaign-controllers/single-select-service";
import Strings from "src/shared/Strings";
import inputTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/input-template.html?raw";
import multiSelectTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/multi-select-template.html?raw";
import singleSelectTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/single-select-template.html?raw";
import tokenizerTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/tokenizer-template.html?raw";
import dropdownTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/dropdown-template.html?raw";
import doubleInputTemplate from "src/shared/ui-components/evaluatable-field/static-ui-templates/double-input-template.html?raw";
import wrapperTemplate from "src/shared/ui-components/evaluatable-field/evaluatable-field-template.html?raw";

const EVALUATABLE_CONTEXTS = {
    BUILDTIME: "buildtime",
    RUNTIME: "runtime",
};

const EVALUATABLE_TYPES = {
    STATIC: "",
    SPEL_BUILDTIME: "spel@buildtime:",
    SPEL_RUNTIME: "spel@runtime:",
    JAVASCRIPT_BUILDTIME: "javascript@buildtime:",
    JAVASCRIPT_RUNTIME: "javascript@runtime:",
};

const EVALUATABLE_TYPES_SORTED = {
    buildtime: {
        STATIC: "",
        SPEL_BUILDTIME: "spel@buildtime:",
        JAVASCRIPT_BUILDTIME: "javascript@buildtime:",
    },
    runtime: {
        STATIC: "",
        SPEL_RUNTIME: "spel@runtime:",
        JAVASCRIPT_RUNTIME: "javascript@runtime:",
    },
};

const DEFAULT_TYPES = {
    array: [],
    string: null,
    boolean: null,
    object: {},
    integer: null,
};

const DEFAULT_UI_TEMPLATES = {
    input: _.template(inputTemplate),
    tokenizer: _.template(tokenizerTemplate),
    multi_select: _.template(multiSelectTemplate),
    single_select: _.template(singleSelectTemplate),
    double_input: _.template(doubleInputTemplate),
    evaluatable: _.template(inputTemplate),
    dropdown: _.template(dropdownTemplate),
};

function getEvaluatableTypes(type) {
    return EVALUATABLE_TYPES_SORTED[type] || EVALUATABLE_TYPES;
}

const EvaluatableFieldView = Backbone.View.extend({
    template: _.template(wrapperTemplate),

    events: {
        "change .js-evaluatable-type": "updateFields",
    },

    initialize(options) {
        this.model = options.model;
        this.eventBus = options.eventBus || _.extend({}, Backbone.Events);
        this.service = options.service;
        this.fieldName = this.$el.data("field-name");
        this.dataType = this.$el.data("type");
        this.dataUIType = this.$el.data("ui-type") || "input";
        this.isNested = this.$el.data("is-nested");
        this.isIgnored = this.$el.data("is-ignored");
        this.options =
            (this.$el.data("options") && this.$el.data("options").split(",")) ||
            [];
        this.allValues = this.$el.data("all-values");
        this.errorCodes = this.$el.data("input-error-codes");
        this.placeholder = this.$el.data("placeholder");
        this.keepCase = this.$el.data("keep-case");
        this.evaluatableType = this.isNested
            ? EVALUATABLE_CONTEXTS.RUNTIME
            : this.model && this.model.supported_evaluatable_type;
        this.data =
            this.model?.get(this.fieldName) ?? DEFAULT_TYPES[this.dataType];
        if (this.isNested) {
            this.data = this.$el.data("value");
        }
        this.uniqueId = _.uniqueId();
        this.eventBus.on(this.eventBus.SAVED, this.setValue.bind(this));
    },

    render() {
        const parsedData = this.parseData(this.data, this.type, this.isNested);
        this.$el.html(
            this.template({
                id: this.uniqueId,
                data: this.data,
                parsedData,
                EVALUATABLE_TYPES: getEvaluatableTypes(
                    this.model && this.model.supported_evaluatable_type
                ),
                hasNestedEvaluatable: this.dataUIType === "evaluatable",
                fieldName: this.fieldName,
                Strings,
            })
        );
        this.$evaluatableTypeExpressionField = this.$(
            `.js-evaluatable-type-expression-field-${this.uniqueId}`
        );
        this.$stringField = this.$(
            `.js-evaluatable-string-field-${this.uniqueId}`
        );
        this.$nestedEvaluatableLabel = this.$(
            `.js-nested-evaluatable-label-${this.uniqueId}`
        );
        this.$staticFieldWrapper = this.$(".js-static-type-wrapper");
        this.$customFieldWrapper = this.$(".js-custom-type-wrapper");
        this.renderStaticField();
        this.updateFields();
        return this;
    },

    renderStaticField() {
        this.$staticFieldWrapper.html(
            DEFAULT_UI_TEMPLATES[this.dataUIType]({
                data: JSON.parse(JSON.stringify(this.data)),
                fieldName: this.fieldName,
                options: this.options,
                allValues: this.allValues,
                errorCodes: this.errorCodes,
                placeholder: this.placeholder,
                isIgnored: this.isIgnored,
                withEditModal: false,
                keepCase: this.keepCase,
                Strings,
            })
        );
        this.$tokenizer = this.$(".js-tokenizer-wrapper");
        this.$multiSelectWrapper = this.$(".js-multi-select-wrapper");
        this.$singleSelectWrapper = this.$(".js-single-select-wrapper");
        tokenizerService.renderTokenizers(this.$tokenizer);
        if (this.dataType === "boolean") {
            singleSelectService.renderBooleanFields(this.$singleSelectWrapper);
        } else {
            singleSelectService.renderSingleSelectFields(
                this.$singleSelectWrapper,
                this.options,
                this.dataType
            );
        }
        multiSelectService.renderMultiSelectFields(this.$multiSelectWrapper);
        const nestedEvaluatable = this.service.renderFields(
            this.$(".js-evaluatable-wrapper-inner"),
            this.model
        );
        this.nestedView = nestedEvaluatable && nestedEvaluatable.view;
    },

    parseData() {
        if (this.type === "object") {
            return JSON.parse(JSON.stringify(this.data));
        }

        return _.isArray(this.data)
            ? {}
            : this.service.parseEvaluatable(this.data || "", this.isNested) ||
                  {};
    },

    updateFields() {
        const type = this.$(".js-evaluatable-type").val();
        const isRuntime = type.indexOf(EVALUATABLE_CONTEXTS.RUNTIME) > -1;
        this.$evaluatableTypeExpressionField.val(type);
        if (_.isEmpty(type)) {
            this.$staticFieldWrapper.show();
            this.$customFieldWrapper.hide();
            this.toggleElement(this.$nestedEvaluatableLabel, true);
        } else {
            this.$staticFieldWrapper.hide();
            this.$customFieldWrapper.show();

            if (this.nestedView) {
                this.$stringField.prop("readonly", !isRuntime);
                this.toggleElement(this.$nestedEvaluatableLabel, isRuntime);
                this.nestedView.toggleView(isRuntime);
            }
        }
    },

    serialize(value) {
        if (this.dataType === "array") {
            return _.isEmpty(value)
                ? DEFAULT_TYPES[this.dataType]
                : value.split(",");
        }

        if (this.dataType === "integer") {
            const parsedInt = Number.parseInt(value, 10);

            if (!isNaN(parsedInt)) {
                return parsedInt;
            }
        }

        if (this.dataType === "boolean") {
            return _.isEmpty(value) ? null : value === "true";
        }

        return _.isEmpty(value) ? null : value;
    },

    getValue() {
        const type = this.$evaluatableTypeExpressionField.val();
        const $valueField = this.$(`[name=${this.fieldName}]`);
        let value = type + this.$stringField.val();

        if (_.isEmpty(type)) {
            value = $valueField.val()?.trim();
            if (this.dataType === "object") {
                value = {};
                this.$(".js-object-field").each(function () {
                    const $this = $(this);
                    if (!_.isEmpty($this.val())) {
                        value[$this.attr("name")] = $this.val();
                    }
                });
            }
            return this.serialize(value);
        }

        if (this.nestedView && !this.nestedView.$el.hasClass("hide")) {
            const val = this.nestedView.getValue();
            return `${type}'${val}'`;
        }

        return value;
    },

    setValue(model) {
        if (this.isNested) {
            return;
        }
        const value = this.getValue();
        if (model) {
            model.set(this.fieldName, value);
        } else {
            this.model.set(this.fieldName, value);
        }
    },

    toggleElement($el, isHidden) {
        $el.toggleClass("hide", isHidden);
    },

    toggleView(isHidden) {
        this.toggleElement(this.$el, isHidden);
    },
});

EvaluatableFieldView.create = function (el, model, eventBus, service) {
    return new EvaluatableFieldView({
        el,
        model,
        eventBus,
        service,
    }).render();
};

export default EvaluatableFieldView;
