import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import CalendarView from "src/shared/calendar/CalendarView";
import template from "src/labs/campaign-controllers/calendar-template.html?raw";

const fieldTemplate = _.template(template);

export default {
    renderFields($selectors) {
        const model = new Backbone.Model();
        $selectors.each(function () {
            const $item = $(this);
            const value = $item.data("value");
            const align = $item.data("align");
            const fieldName = $item.data("field-name");
            const placeholder = $item.data("placeholder");
            const withTime = !_.isUndefined($item.data("with-time"));
            const withSupportOmissible = !_.isUndefined(
                $item.data("is-omissible")
            );
            $item.addClass("calendar-field-wrapper");
            $item.html(
                fieldTemplate({
                    placeholder,
                })
            );
            CalendarView.create($item)
                .withName(fieldName)
                .withAlign(align || "")
                .withModel(model)
                .withValue(value)
                .withSupportTime(withTime)
                .withSupportOmissible(withSupportOmissible)
                .build();
        });
    },
};
