import $ from "jquery";
import _ from "underscore";
import DataTypes from "src/labs/campaign-controllers/DataTypes";
import Strings from "src/shared/Strings";
import singleSelectFieldTemplate from "src/labs/campaign-controllers/single-select-field-template.html?raw";
import Foundation from "foundation";

const template = _.template(singleSelectFieldTemplate);

const toggleSingleSelectOption = ($wrapper, fieldName, $target) => {
    $wrapper.find(`[name='${fieldName}']`).val($target.data("option")).change();
};

export default {
    renderSingleSelectFields($selector, values, dataType) {
        $selector.each(function () {
            const $item = $(this);
            const requiredError = $item.data("required-error");
            const fieldName = $item.data("field-name");
            const items = $item.data("items");
            const value = $item.data("value");
            const isOmissible = $item.data("isOmissible");
            $item.html(
                template({
                    Strings,
                    requiredError,
                    fieldName,
                    isOmissible,
                    dataType: dataType || DataTypes.STRING,
                    items: values || items.split(","),
                    value: decodeURIComponent(value),
                })
            );
            Foundation.init($item, {
                tab: {},
            });
            $item.find("[data-option]").on("click", function (event) {
                event.preventDefault();
                const $target = $(this);
                toggleSingleSelectOption($item, fieldName, $target);
            });
            if (
                fieldName === "filter_expression" &&
                decodeURIComponent(value) !== "false"
            ) {
                $item.find("[data-option='true']").find("a").trigger("click");
                $item.find("[data-option='false']").addClass("disabled");
            }
        });
    },

    renderBooleanFields($selector) {
        this.renderSingleSelectFields(
            $selector,
            ["true", "false"],
            DataTypes.BOOLEAN
        );
    },
};
