import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import CodeEditorView from "src/shared/ui-components/code-editor/CodeEditorV2";
import ConfirmationModalView from "src/shared/modals/ConfirmationModalView";
import template from "src/shared/ui-components/evaluatable-field/edit-evaluatable-modal-template.html?raw";

const { EVENTS } = ConfirmationModalView;

const EditEvaluatableModalView = Backbone.View.extend(
    {
        modalTemplate: _.template(template),

        initialize(options) {
            this.sourceField = options.sourceField;
            this.targetField =
                options.targetField && options.targetField.length
                    ? options.targetField
                    : this.sourceField;
            this.templatedValue = options.templatedValue;
            this.editedValue = this.templatedValue || this.sourceField.val();
            this.modal = new ConfirmationModalView.Builder()
                .withTitle("Edit as evaluatable")
                .withInnerHTML(this.modalTemplate())
                .withAdditionalClassname("modal-wrapper--wide")
                .withConfirmButtonTitle("Done")
                .build();
            this.model = new Backbone.Model();
            this.codeEditor = CodeEditorView.create(
                this.modal.$el.find(".js-code"),
                this.editedValue.toString(),
                "code-editor__wrapper--small",
                "evaluatable-modal"
            );
            this.listenTo(
                this.modal,
                EVENTS.CONFIRMED,
                this.onConfirmed.bind(this)
            );
            this.listenTo(this.modal, EVENTS.CANCELED, this.close.bind(this));
        },

        onConfirmed() {
            this.editedValue = this.codeEditor?.setValue();
            const nameValue = this.sourceField.attr("name");
            this.sourceField.removeAttr("name");
            this.targetField.attr("name", nameValue);
            this.targetField.val(this.editedValue);
            this.trigger(EVENTS.CONFIRMED, this.editedValue);
            this.modal.close();
            this.close();
        },

        close() {
            this.undelegateEvents();
            this.remove();
        },
    },
    {
        EVENTS,
    }
);

EditEvaluatableModalView.create = function (sourceField, templatedValue) {
    return new EditEvaluatableModalView({
        sourceField,
        templatedValue,
    });
};

export default EditEvaluatableModalView;
