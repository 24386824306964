import $ from "jquery";
import EvaluatableFieldView from "src/shared/ui-components/evaluatable-field/EvaluatableFieldView";
import EditableEvaluatableFieldView from "src/shared/ui-components/evaluatable-field/EditableEvaluatableFieldView";
import eventBus from "src/shared/ui-components/evaluatable-field/event-bus";

const EVALUATABLE_REGEX = /(spel|javascript)@(build|run)time:/;

const QUOTES_REGEX = /^["']|["']$/g;

export default {
    renderFields($selector, model) {
        const self = this;
        let view;
        $selector.each(function () {
            const $item = $(this);
            const withEditButton = $item.hasClass(
                "js-evaluatable-with-edit-wrapper"
            );
            view = withEditButton
                ? EditableEvaluatableFieldView.create($item, model, self)
                : EvaluatableFieldView.create($item, model, eventBus, self);
        });
        return {
            eventBus,
            view,
        };
    },

    isEvaluatable(string) {
        return EVALUATABLE_REGEX.test(string);
    },

    parseEvaluatable(string, isNested) {
        const parsed = {};
        if (this.isEvaluatable(string)) {
            const [parsedType] = EVALUATABLE_REGEX.exec(string);
            const parsedExpression = isNested
                ? string.replace(QUOTES_REGEX, "")
                : string;
            parsed.type = parsedType;
            parsed.expression = parsedExpression.replace(parsed.type, "");
            return parsed;
        }
        return string;
    },
};
